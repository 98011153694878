import React from "react"
import { Layout } from "@components/Layout"
import ForgotPassword from "@auth/ForgotPassword"

import { SEO } from "@components/SEO"
import { graphql, useStaticQuery } from "gatsby"

const ForgotPasswordPage = () => {
  const data = useStaticQuery(
    graphql`{
      site {
        siteMetadata {
          apiUrl
        }
      }
    }
    `,
  );
  const apiUrl = data.site.siteMetadata.apiUrl
  return (
    <Layout version="blank">
    <SEO title="Forgot Password" />
    <ForgotPassword apiUrl={apiUrl}/>
  </Layout>
  )
  
}

export default ForgotPasswordPage
